<template>
	<page>
		<module>
			<c-form ref="form" style="max-width: 1100px;" >
				<c-title>基本信息</c-title>
				<c-form  column="2" unit-width="95%">
					<c-form-item label="委外单号">
						<c-input  type="hidden" name="id"></c-input>
						<c-input v-model="iconsigned_order_number"  type="text"  name="iconsigned_order_number" disabled></c-input>
					</c-form-item>
					<c-form-item label="委外类型">
						<c-select :options="DIC_ICONSIGNED_TYPE" name="title"></c-select>
					</c-form-item>
					<c-form-item label="委托方" required>
						<c-input type="hidden" name="manufacture_id"></c-input>
						<div class="flex-center">
							<c-input class="flex-grow" name="manufacture_name" maxlength="50" readonly disabled></c-input>
							<c-button color="sys" style="margin-left: 10px;" @click="selectManufacture">选择委托方</c-button>
						</div>
					</c-form-item>
					<c-form-item label="委托方联系人" required>
						<c-input type="text" name="manufacture_contacts" disabled></c-input>
					</c-form-item>
					<c-form-item label="负责员工" required>
						<c-input type="hidden" name="salesman_id"></c-input>
						<div class="flex-center">
							<c-input class="flex-grow" name="salesman_name" maxlength="50" readonly disabled></c-input>
							<c-button color="sys" style="margin-left: 10px;" @click="selectStaff">选择员工</c-button>
						</div>
					</c-form-item>
					<c-form-item label="关联订单">
						<c-input type="text" name="order_number" maxlength="20"></c-input>
					</c-form-item>
					<c-form-item label="交货日期">
						<c-datetime type="date" name="delivery_schedule_date"></c-datetime>
					</c-form-item>
					<c-form-item label="订单备注" colspan="2">
						<c-input type="textarea" name="order_remark"></c-input>
					</c-form-item>
				</c-form>
				<c-title>生产产品</c-title>
				<module enable-flex>
					<div class="flex-start" style="padding-bottom: 5px">
						<c-button v-show="this.product_type==0||this.product_type==1" @click="selectProduct(1)">添加产品</c-button>
						&nbsp;&nbsp;
						<c-button v-show="this.product_type==0||this.product_type==2" @click="selectProduct(2)">添加物料</c-button>
					</div>
					<c-table ref="product_table" :data="productData" :paging="false" height="300" border>
<!--						<c-table-column
							label="产品编码"
							name="product_code"
							width="100"
						>
						</c-table-column>-->

						<c-table-column
							label="产品名称"
							name="product_name"
							width="300"
						>
						</c-table-column>

						<c-table-column
							label="产品颜色"
							name="product_spec"
							width="150"
						>
							<template #default="{data}">
								<span v-if="data.product_spec">{{data.product_spec}}</span>
								<span v-else>--</span>
							</template>
						</c-table-column>

						<c-table-column
							label="产品类型"
							name="product_type"
							width="80"
						>
							<template #default="{data}">
								<span v-if="data.product_type==1">成品</span>
								<span v-if="data.product_type==2">物料</span>
							</template>
						</c-table-column>

						<c-table-column
							label="计划生产数量"
							name="product_count"
							width="80"
							required
						>
							<template #default="{data}">
								<c-input name="product_count" type="number" decimal="5" range="0.00001,999999"  width="80" v-model="data.product_count"></c-input>
							</template>
						</c-table-column>

						<c-table-column
							label="单位"
							name="unit"
							width="50"
						>
						</c-table-column>

						<c-table-column
							label="备注"
							name="remark"
							width="150"
						>
							<template v-slot="{data}">
								<c-input name="remark" type="text" width="120" v-model="data.remark"></c-input>
							</template>
						</c-table-column>

						<c-table-column
							type="button"
							label="操作"
							width="50"
						>
							<template v-slot="{data}">
								<c-table-button @click="removeProduct(data)">删除</c-table-button>
							</template>
						</c-table-column>
					</c-table>
				</module>
				<c-title>物料清单（注：下方填写内容为委外携带物料清单，无可不填写）</c-title>
				<module enable-flex>
					<div class="flex-start" style="padding-bottom: 5px">
						<c-button @click="selectMaterial(2)">添加物料</c-button>
					</div>
					<c-table ref="product_table" :data="materialData" :paging="false" height="300" border>
<!--						<c-table-column
							label="物料编码"
							name="product_code"
							width="100"
						>
						</c-table-column>-->

						<c-table-column
							label="物料名称"
							name="product_name"
							width="300"
						>
						</c-table-column>

						<c-table-column
							label="单位"
							name="unit"
							width="50"
						>
						</c-table-column>

						<c-table-column
							label="物料数量"
							name="product_count"
							width="80"
							required
						>
							<template #default="{data}">
								<c-input name="product_count" type="number" decimal="5" range="0.00001,999999"  width="80" v-model="data.product_count"></c-input>
							</template>
						</c-table-column>

						<c-table-column
							label="备注"
							name="remark"
							width="150"
						>
							<template v-slot="{data}">
								<c-input name="remark" type="text" width="120" v-model="data.remark"></c-input>
							</template>
						</c-table-column>

						<c-table-column
							type="button"
							label="操作"
							width="50"
						>
							<template v-slot="{data}">
								<c-table-button @click="removeMaterial(data)">删除</c-table-button>
							</template>
						</c-table-column>
					</c-table>
				</module>
			</c-form>

			<template slot="button">
				<c-button color="main" size="form" @click="submit(1)">确认订单</c-button>
				<c-button size="form" @click="submit(-2)">暂存草稿</c-button>
			</template>
		</module>
		<!--选择负责员工-->
		<staff-selector ref="staffSelector" @resolve="selectStaffHandle"></staff-selector>
		<!--选择委托生产商-->
		<manufacture-selector ref="manufactureSelector" @resolve="selectManufactureHandle"></manufacture-selector>
		<!--产品选择弹框-->
		<product-selector ref="productSelector" multiple @resolve="handleProductSelect"></product-selector>
		<!--物料选择弹框-->
		<material-selector ref="materialSelector" multiple @resolve="handleProductSelect"></material-selector>
		<!--物料选择弹框-->
		<other-material-selector ref="otherMaterialSelector" multiple @resolve="handleMaterialSelect"></other-material-selector>
	</page>
</template>

<script>
import staffSelector from '@/components/service/sys_user_selector.vue'//选择员工
import manufactureSelector from '@/components/service/manufacture_selector.vue'
import materialSelector from "@/components/service/bom_material_selector.vue";
import otherMaterialSelector from "@/components/service/bom_material_selector.vue";
import productSelector from "@/components/service/product_selector.vue";
import {mapState} from "vuex";
import {asyncMapState} from "@/store";
//选择委托生产商
export default {
	name: 'm_iconsigned_order_edit',
	props: {
		id: String
	},
	components: {
		productSelector, materialSelector,
		staffSelector,manufactureSelector,
		otherMaterialSelector
	},
	data() {
		return {
			product_type:0,
			iconsigned_order_number: '',
			productData:[],
			materialData:[]
		}
	},
	computed: {
		...mapState(['systemConfig']),
		...asyncMapState(['DIC_ICONSIGNED_TYPE'])
	},

	watch: {
		id() {
			this.$refs.form.clear();
			if (this.id) {
				this.getOrderDetail();
			}
		}
	},

	mounted() {
		if (this.id) {
			this.getOrderDetail();
		}
	},

	methods: {
		//查询订单详情
		getOrderDetail() {
			this.request({
				url: '/wms/iconsigned/order/detail',
				data: {
					id:this.id
				},
				loading: true,
				success: async data => {
					this.$refs.form.set({
						manufacture_id: data.manufacture_id,
						iconsigned_order_number: data.iconsigned_order_number,
						manufacture_name: data.manufacture_name,
						manufacture_contacts: data.manufacture_contacts+'/'+data.manufacture_phone,
						title: data.title,
						salesman_id: data.salesman_id,
						salesman_name: data.salesman_name,
						order_number: data.order_number,
						delivery_schedule_date: data.delivery_schedule_date,
						order_remark: data.order_remark
					});
					this.iconsigned_order_number = data.iconsigned_order_number;
					this.productData = data.product_list||[];
					this.materialData = data.material_list||[];
					this.product_type = this.productData[0].product_type;
				}
			});
		},
		//选择委托方
		selectManufacture(){
			this.$refs.manufactureSelector.open();
		},
		//选择委托方回调
		selectManufactureHandle(data) {
			var obj = data[0];
			this.$refs.form.set({
				manufacture_id: obj.id,
				manufacture_name: obj.manufacture_name,
				manufacture_contacts: obj.manufacture_contacts+'/'+obj.manufacture_phone
			});
		},
		//选择员工
		selectStaff() {
			this.$refs.staffSelector.open('','负责员工',3);
		},
		//选择员工回调
		selectStaffHandle(data) {
			var obj = data[0];
			this.$refs.form.set({
				salesman_id: obj.id,
				salesman_name: obj.user_realname
			});
		},
		//选择产品(或物料)
		selectProduct(type){
			var product_ids = [];
			if (this.productData && this.productData.length > 0){
				this.productData.forEach(item => {
					product_ids.push(item.product_id);
				});
			}
			//产品弹框
			if(type == 1){
				this.$refs.productSelector.open(0,product_ids);
				//物料弹框
			}else if(type == 2){
				this.$refs.materialSelector.open(0,product_ids);
			}
		},
		//删除产品
		removeProduct(data){
			// 删除指定元素
			let index = this.productData.indexOf(data);
			if (index !== -1) {
				this.productData.splice(index, 1);
			}
			if (!this.productData || this.productData.length==0){
				this.product_type = 0;
			}
		},
		//确认选择产品
		handleProductSelect(rows){
			if (rows){
				rows.forEach(item => {
					var product = {};
					product.product_id = item.id;
					product.product_code = item.product_code;
					product.product_name = item.product_name;
					product.unit = item.unit;
					product.product_spec = item.product_spec;
					product.product_type = item.type;
					product.remark = '';
					this.product_type = item.type;
					this.productData.push(product);
				});
			}
		},
		//选择物料
		selectMaterial(){
			var material_ids = [];
			if (this.materialData && this.materialData.length > 0){
				this.materialData.forEach(item => {
					material_ids.push(item.product_id);
				});
			}
			//物料弹框
			this.$refs.otherMaterialSelector.open(0,material_ids);
		},
		//删除产品
		removeMaterial(data){
			// 删除指定元素
			let index = this.materialData.indexOf(data);
			if (index !== -1) {
				this.materialData.splice(index, 1);
			}
		},
		//确认选择产品
		handleMaterialSelect(rows){
			if (rows){
				rows.forEach(item => {
					var product = {};
					product.product_id = item.id;
					product.product_code = item.product_code;
					product.product_name = item.product_name;
					product.unit = item.unit;
					product.remark = '';
					this.materialData.push(product);
				});
			}
		},
		/**
		 * 提交数据
		 * state = 1 提交数据
		 * state = -2 草稿
		 */
		submit(state) {
			if (state == 1){
				// 确认弹窗
				this.$confirm({
					title: '确认订单',
					message: '是否提交委外订单数据，提交后将不再允许修改',  // 提示内容,
					buttonName: '确定',  // 确定按钮文字
					cancelButton: true,  // 是否显示取消按钮
					resolve: () => {
						this.submitData(state);
					},  // 确定按钮回调
					cancel: () => {
					},  // 取消按钮回调
				});
			}else {
				this.submitData(state);
			}
		},
		/**
		 * 提交数据
		 * state = 1 提交数据
		 * state = -2 草稿
		 */
		submitData(state) {
			//校验数据
			this.$refs.form.submit({
				url: '/wms/iconsigned/order/edit',
				rule: {
					manufacture_id: '请选择委托方',
					salesman_id: '请选择负责员工',
					productData: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.productData.length > 0){
								var flag = true;
								this.productData.forEach(item => {
									if(!item.product_count || parseFloat(item.product_count) <= 0){
										flag = false;
									}
								});
								return flag;
							}else{
								return false;
							}
						},
						message: '请正确填写生成产品信息'
					}],
					materialData: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.materialData.length > 0){
								var flag = true;
								this.materialData.forEach(item => {
									if(!item.product_count || parseFloat(item.product_count) <= 0){
										flag = false;
									}
								});
								return flag;
							}else{
								return true;
							}
						},
						message: '请正确填写物料清单信息'
					}]
				},
				dataFormatter: data => {
					data.id = this.id;
					data.state = state;
					data.product_list = this.productData;
					data.material_list = this.materialData;
					return data;
				},
				success: (data, res) => {
					this.iconsigned_order_number = data.iconsigned_order_number;
					this.id = data.id;
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.$bus.$emit('iconsignedOrderList.update',{});//刷新订单列表
					//打开列表
					this.redirectTo('/iconsigned/order');
				}
			});
		}
	}
}
</script>

<style lang="stylus" scoped>
	.box {
		box-sizing: border-box;
		border: 1px dotted #ddd;
		padding: 14px;
	}
	
	.area {
		position: relative;
		padding: 20px 20px 0;
		background: #f9f8fb;
	}
	
	.spec {
		&:nth-child(2) {
			margin-left: 10px;
		}
		
		&-head {
			line-height: 36px;
			font-size: 14px;
			color: #666;
			
			&:nth-last-child(1) {
				padding-bottom: 10px;
			}
		}
		
		&-item {
			&:nth-child(n+2) {
				margin-top: 6px;
			}
		}
		
		&-price {
			label {
				padding: 0 5px;
				line-height: 24px;
				font-size: 12px;
			}
			
			&:nth-child(n+2) {
				margin-left: 10px;
			}
			
			.c-input {
				width: 80px;
				height: 30px;
				
				>>> input {
					text-align: center;
				}
			}
		}
		
		&-total {
			line-height: 50px;
			font-size: 13px;
			
			span {
				margin-right: 20px;
			}
		}
		
		&-remove {
			position: absolute;
			right: 0;
			top: 0;
			width: 24px;
			line-height: 24px;
			font-size: 12px;
			text-align: center;
			color: #fff;
			background: #aaa;
			
			&:hover {
				background: #999;
			}
		}
		
		.size {
			&:nth-child(n+2) {
				margin-left: 10px;
			}
			
			&-ipt {
				width: 44px;
				height: 30px;
			}
			
			label {
				padding: 0 5px;
				line-height: 24px;
				font-size: 12px;
			}
			
			>>> input {
				text-align: center;
			}
		}
	}
</style>