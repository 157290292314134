<template>
	<c-dialog ref="dialog" title="选择委托生成商" width="660" @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
			<m-search-item label="生成商名称">
				<c-input name="manufacture_name"></c-input>
			</m-search-item>
			<m-search-item label="联系人">
				<c-input name="manufacture_contacts"></c-input>
			</m-search-item>
			<m-search-item label="联系电话">
				<c-input name="manufacture_phone"></c-input>
			</m-search-item>
		</m-search>
		<c-table ref="table" height="400">
			<c-table-column
				type="selector"
				:multiple="!!multiple"
			></c-table-column>

			<c-table-column
				label="生成商名称"
				width="150"
				name="manufacture_name"
			></c-table-column>

			<c-table-column
				label="联系人"
				width="150"
				name="manufacture_contacts"
			></c-table-column>

			<c-table-column
				label="联系电话"
				width="100"
				name="manufacture_phone"
			></c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean
		},
		
		methods: {
			open() {
				this.$refs.dialog.open();
				this.$refs.search.search();
			},
			//搜索产品
			searchHandle(data){
				this.$refs.table.load({
					url: '/manufacture/list',
					data
				});
			},
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择委托生成商'
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			}
		}
	}
</script>